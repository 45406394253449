import { Observable } from 'rxjs';
import { LoginResponse, LoginRequest } from '../dto/login.dto';
import { ChangeFirstPasswordRequest, ChangeFirstPasswordResponse } from '../dto/changeFirstPassword.dto';

export abstract class AuthRepository {
  abstract login(data: LoginRequest): Observable<LoginResponse>;

  abstract logout(): void;

  abstract isNewPassword(): boolean;

  abstract updateIsNewPasswordStatus(): void;

  abstract getToken(): string;

  abstract isLogged(): boolean;

  abstract changeFirstPassword(data: ChangeFirstPasswordRequest): Observable<ChangeFirstPasswordResponse>;

  abstract comparePermission(permission: string): boolean;

}
